<template>
  <div class="common_view">
    <div class="content">
      <div class="close">
        <i class="el-icon-circle-close" @click="closeAction"></i>
      </div>
      <div class="infinite-list scroll_list" style="overflow: auto">
        <keep-alive include="/(Live)$/">
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommonView",
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.common_view {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 55%;
    height: 95%;
    background-color: #fff;
    position: relative;
    .close {
      padding: 7px 10px;
      text-align: right;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 500;
      i {
        font-size: 30px;

        &:hover {
          cursor: pointer;
          color: #409eff;
        }
      }
    }
    .scroll_list {
      height: 85%;
    }
  }
}
</style>
